import React from "react";
import NewsArticleLayout from "../../../components/updated-article/NewsArticleLayout.component";

const isNew = true;
const href = "/ratesheet/20230901-Rate-Sheet.pdf";
const date = "01.09.2023";

const Article = () => (
  <NewsArticleLayout href={href} date={date} isNew={isNew}>
    <h1 className="page-title">Changes to our mortgage products</h1>

    <br />
    <p>With effect from Friday 1 September 2023 we will be making the following changes to our range:</p>
    <p><strong>Summary of changes</strong></p>
    <p><strong>The Buy to Let Standard Variable Rate has changed from 7.35% to 7.60%. There is no change to the Residential SVR.</strong></p>
    <p>There are no changes to any other interest rates at this time.</p>
    <p>An updated mortgage rate sheet will be published on Friday 1 September 2023 to reflect these changes.</p>
  </NewsArticleLayout>
);

export default Article;
